<template>
  <swiper
    :slidesPerView="1"
    :lazy="true"
    :loop="true"
    :navigation="false"
    :speed="1500"
    :autoplay='{
        "delay": 3000,
        "disableOnInteraction": false,
        "pauseOnMouseEnter":true
    }'
  >
    <swiper-slide>
        <img src="@/assets/images/slider_mini/bad_animation01.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_mini/bad_animation02.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_mini/bad_animation03.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_mini/bad_animation04.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_mini/bad_animation05.jpg">
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import { defineComponent } from '@vue/runtime-core'

SwiperCore.use([Autoplay, Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange
    }
  }
})
</script>

<style lang="scss">
:root {
    --swiper-theme-color: #ffffff;
}
</style>
