<template>
    <footer class="bg-white p-12">
        <div class="container">
            <ul>
                <li class="grid grid-cols-2">
                    <img class="col-start-1" src="@/assets/images/logo.svg" width="225" loading="lazy">
                    <img class="m-auto mr-0" src="@/assets/images/logo_circle.png" width="50" loading="lazy">
                </li>
                <li>
                    <p>Fliesen & Naturstein Sarecz</p>
                    <p>Brandmühlenstraße 4</p>
                    <p>101731 Kreischa OT Kautzsch</p>
                    <p>Inhaber: Atilla Sarecz</p>
                </li>
                <li class="pt-5">
                    <p><a href="tel:035206394880">Telefon: 035206 394880</a></p>
                </li>
                <li>
                    <p>
                        Mail:
                        <a href="mailto:info@badwerk-dresden.de"
                            >info@badwerk-dresden.de</a
                        >
                    </p>
                    <p>Internet: <a href="https://www.badwerk-dresden.de">www.badwerk-dresden.de</a></p>
                </li>
            </ul>
        </div>
        <div class="container pt-5 flex flex-col md:flex-row justify-between items-center">
            <a href="https://www.instagram.com/fliesen_dresden/"><img src="@/assets/images/instagram.png" width="40" loading="lazy"></a>
            <div class="uppercase py-3 flex flex-col md:flex-row items-center">
                <router-link class="text-highlight" to="/impressum">Impressum</router-link>
                <span class="text-highlight hidden md:block px-3"> / </span>
                <router-link class="text-highlight" to="/datenschutzerklaerung">Datenschutzerklärung</router-link>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
export default {}
</script>
<style lang=""></style>
