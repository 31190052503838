<template lang="html">
    <section
        class="flex justify-center bg-cover bg-center bg-no-repeat w-full text-white py-20"
        style="background-image: url(https://images.unsplash.com/photo-1604709177225-055f99402ea3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80)"
    >
        <div
            class="bg-black bg-opacity-50 max-w-screen-lg m-auto content-center py-10 md:p-10"
        >
            <h1 class="text-3xl md:text-5xl text-center">
                Ein Bad aus einer Hand - Ihr Badwerk aus Dresden und Umgebung plant, gestaltet und realisiert Ihr Traumbad!
            </h1>
            <h6 class="text-center text-lg md:text-1xl py-10">
                Ihr Badexperte für Fliesen & Bäder in Dresden und Umgebung der
                Ihnen Zeit, Geld und Nerven spart - garantiert
            </h6>
            <ul
                class="flex flex-col lg:flex-row justify-between items-center text-center px-20"
            >
                <li>
                    <img src="@/assets/images/google_kundenzufriedenheit.png" width="200">
                </li>
                <li class="py-4">
                    <button @click="scroll('badsanierungen')" class="bg-highlight text-white font-extralight p-4 px-8 rounded uppercase">Jetzt mehr erfahren</button>
                </li>
                <li>
                    <img src="@/assets/images/20_years.png" width="200">
                </li>
            </ul>
            <ul class="flex flex-col lg:flex-row justify-center md:justify-between sm:items-center text-center px-10 pt-10">
                <li class="flex flex-row flex-nowrap items-center pl-2">
                    <img src="@/assets/images/badewanne.png" width="45">
                    <span class="pl-2 uppercase text-sm">Badsanierung</span>
                </li>
                <li class="flex flex-row flex-nowrap items-center pl-2 py-5">
                    <img src="@/assets/images/kacheln.png" width="45">
                    <span class="pl-2 uppercase text-sm">Fliesen- & Natursteinverlegung</span>
                </li>
                <li class="flex flex-row flex-nowrap items-center pl-2">
                    <img src="@/assets/images/werkzeug_blau.png" width="38">
                    <span class="pl-2 uppercase text-sm">Reparatur & Service</span>
                </li>
            </ul>
        </div>
    </section>
    <section class="bg-bad-gray-light" id="badsanierungen">
        <div class="container pt-2 p-10 md:p-20 md:pt-4">
            <img class="m-auto pb-8" src="@/assets/images/pfeil.png" width="100" loading="lazy">
            <div class="m-auto grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
                <div class="text-bad-gray-dark m-auto">
                    <h1 class="text-4xl md:text-5xl font-light">
                        Badsanierungen aus einer Hand in der Umgebung von Dresden
                    </h1>
                    <h3 class="py-10 font-semibold leading-relaxed">
                        Neubau, Umbau oder Renovierung - Mit dem Fachbetrieb
                        rund um Dresden, Nossen & Großenhain zum Traumbad
                    </h3>
                    <p class="font-light leading-relaxed">
                        Fliesen Sarecz plant mit viel Leidenschaft und realisiert mit viel Herzblut Bad-Oasen,
                        die glücklich machen und das transparent zum individuellen Festpreis! Ob komplette
                        Bad-Neuplanung oder Badsanierung, exklusiver
                        Wellness-Bäder mit Sterne-Niveau, stilvolles Familienbad
                        oder nur eine barrierefrei ebenerdige Dusche: wir
                        überraschen mit frischen Ideen und machen aus Ihrem
                        alten Badzimmer in kurzer Zeit eines zum Verlieben!
                    </p>
                    <button @click="scroll('kontakt')" class="bg-highlight text-white font-extralight p-3 rounded my-8">Jetzt kostenfrei beraten lassen</button>
                </div>
                <div>
                    <iframe class="m-auto w-full md:w-5/6 h-3/5 md:h-64" src="https://www.youtube-nocookie.com/embed/_8I5sXuExVo?controls=0&frameborder=0&disablekb=1&fs=0&modestbranding=1&iv_load_policy=3&rel=0&showinfo=0"></iframe>
                    <img class="m-auto py-8 mb-32 md:pt-20" src="@/assets/images/planung_koepfchen.png" width="450" loading="lazy">
                </div>
            </div>

            <details class="text-bad-gray-dark leading-relaxed">
                <summary class="text-highlight list-none">Mehr zum Thema Badgestaltung</summary>
                <div class="column-count-3">
                    <span>Badezimmer Ideen lassen sich vielfältig umsetzten. Neben dem modernen spartanischen Badezimmer lassen sich Badideen
                    auch in die Richtung <b>"Bad im Landhausstil"</b> oder <b>im mediterranen Stil</b> verwirklichen. Des Weiteren
                    gibt es auch noch viele weitere Stile, vom <b>industriellen Stil</b> bis zum <b>barocken Badezimmer</b>. Mit Retro-Armaturen
                    machen Sie im Handumdrehen ein <b>Vintage-Bad</b>. Mit farbenfrohen Mosaikfliesen geht es in Richtung <b>Jugendstil</b>, </span>

                    <span>mit einer <b>freistehenden Badewanne</b> als eindrucksvolle Eyecatcher oder der Handtuchschrank aus natürlichen Holz. Das
                    Badezimmer verwandelt sich so, in dem man sich gerne für längere Zeit aufhält und Körper und Seele Gutes tut. Möchten Sie mehr
                    über uns und unsere Möglichkeiten der Badsanierung erfahren? Dann rufen Sie am besten gleich an und vereinbaren Sie mit uns einen
                    kostenfreien Erstberatungstermin! Wir freuen uns auf Sie.</span>
                </div>
            </details>
        </div>
    </section>
    <section class="bg-bad-gray-dark text-white text-center p-10">
        <h1 class="text-3xl md:text-5xl font-light pb-5">Unsere Leistungen - alles rund um Fliesen!</h1>
        <h3 class="font-light text-lg md:text-xl">Planung + Material <span class="text-highlight">+</span> Handwerk <span class="text-highlight">+</span> Service <span class="text-highlight">=</span> 100%ige Ergebnisse</h3>
    </section>
    <section class="flex justify-center md:justify-end bad-card--right bg-cover bg-no-repeat bg-center p-5 md:p-20">
        <!-- Card right -->
        <div class="max-w-md py-8 md:px-14 px-10 bg-white shadow-lg my-10 md:mr-20 text-center">
            <img src="@/assets/images/badewanne.png" width="75" class="mx-auto" loading="lazy">
            <h1 class="text-3xl font-semibold py-3">Badezimmer <br/>Neubau + Renovierung</h1>
            <p class="px-5 leading-relaxed">
                Erleben Sie selbst, wie bei unserer Badrenovierung aus einem
                gewöhnlichen Bad ein smarter Wellnesstempel wird. Ganz nach
                Ihren Vorstellungen und Wünschen.
            </p>
        </div>
        <!-- Card -->
    </section>
        <section class="flex justify-center md:justify-start bad-card--left bg-cover bg-no-repeat bg-bottom p-5 md:p-20">
        <!-- Card left -->
        <div class="max-w-md py-8 md:px-14 px-10 bg-white shadow-lg my-10 md:mr-20 text-center">
            <img src="@/assets/images/kacheln.png" width="75" class="mx-auto" loading="lazy">
            <h1 class="text-3xl font-semibold py-3">Fliesen + Naturstein</h1>
            <p class="px-5 leading-relaxed">
                Ob klassische oder trendig, groß- formatige Fliesen oder Mosaik – gemeinsam mit unserem Fachhändler
                bieten wir eine breite Auswahl ausgefallener und qualitativ hochwertiger Fliesen. Wir verlegen
                mit reinster Detailliebe Fliesen im Innen- und Außenbereich.
            </p>
        </div>
        <!-- Card -->
    </section>
    <section class="bg-bad-gray-light p-10 md:p-20">
        <div class="container">
        <h1 class="text-3xl md:text-5xl text-center">Warum Sie Ihr Bad in unsere Hände legen sollten?</h1>
            <ul class="columns-2 list pt-10 leading-relaxed">
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Keine Extrakosten - mit Festpreisgarantie</h6>
                    <span>
                        Keine Überraschungen bei der Badezimmersanierung.
                        Modernste Planung und vereinbarte Festpreise machen es möglich!
                    </span>
                </li>
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Frische Ideen und individuelle Badgestaltung</h6>
                    <span>
                        Inspirieren Sie sich mit vielen Bildern unserer Traumbäder in unserer Galerie!
                    </span>
                </li>
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Alles aus einer Hand - bequem und stressfrei</h6>
                    <span>
                        Wir liefern Ihnen eine Sanierung unter Einbeziehung aller Gewerke nach Ihren individuellen
                        Ansprüchen und garantieren Ihnen einen termingerechten Ablauf.
                    </span>
                </li>
                <li class="px-8 pb-6">
                    <h6 class="font-bold">Kurzfristige Besichtigungstermine</h6>
                    <span>
                        Kein langes Warten auf einen Besichtigungstermin vor Ort. Wir reagieren schnell und beraten Sie zeitnah
                    </span>
                </li>
                <li class="px-8 pb-5">

                    <div>
                    <h6 class="font-bold">3D Entwurfsplanung für Ihr Wunschbad</h6>
                    <span>
                        Nach der Grundlagenermittlung erarbeiten wir auf Basis der abgefragten Wünsche eine perfekte 3D Animation mit genauen Gestaltungsideen.
                    </span>
                    </div>
                </li>
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Zeitnahes Angebot</h6>
                    <span>
                        In der Regel ist die wichtigste Information der Preis, deshalb sorgen wir dafür, dass Sie dieses zügig erhalten.
                    </span>
                </li>
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Wir sind Fliesenleger & Handwerker mit Leib und Seele</h6>
                    <span>
                        Lieblose Räume gehören der Vergangenheit an, mit viel Herzblut und Leidenschaft verwandeln wir Ihr Bad in ein Traumbad mit Wohlfühlfaktor,
                        denn Fliesen ist unsere Passion.
                    </span>
                </li>
                <li class="px-8 pb-5">
                    <h6 class="font-bold">Fast 20 Jahre Erfahrung</h6>
                    <span>
                        Wir können fast 20 Jahre handwerkliche Erfahrung in die Waagschale werfen.
                    </span>
                </li>
            </ul>
        </div>
    </section>
    <section class="content--big bg-highlight text-white p-10 md:p-20">
        <div class="container bg_logo bg-contain bg-no-repeat bg-center">
            <h3 class="text-2xl md:text-3xl text-center font-semibold">In 3 Schritten zum Wunschbad - Badsanieren leicht gemacht</h3>
            <ul class="flex flex-col md:flex-row text-center justify-center items-top pt-20">
                <li class="md:px-10 w-full md:w-2/6">
                    <img src="@/assets/images/sprechblase.png" width="75" class="m-auto" loading="lazy">
                    <h6 class="text-2xl py-8 md:px-8">1. Schritt <br> Beratung & Angebot</h6>
                    <p class="font-extralight leading-relaxed">Eine perfekte Badsanierung - nicht ohne erstklassige Beratung und einem fairen Angebot - das dürfen Sie
                        von uns aus erwarten
                    </p>
                    <p class="py-6 font-extralight leading-relaxed">
                        Eine gemeinsame Besichtigung bei Ihnen vor Ort ist die Voraussetzung, um einen Eindruck vom Raum zu bekommen und
                        um sich mit den Gegebenheiten vertraut zu machen.
                    </p>
                </li>
                <li class="md:px-10 w-full md:w-2/6">
                    <img src="@/assets/images/computer.png" width="75" class="m-auto" loading="lazy">
                    <h6 class="text-2xl py-8 md:px-8">2. Schritt <br> Bad neu planen</h6>
                    <p class="font-extralight leading-relaxed">
                        Es kommt auf jedes Detail an. Kreative Vorschläge und eine gute Planung. Ob klein und verwinkelt oder geräumig
                        damit das Endergebnis begeistert, muss alles mit viel Weitsicht geplant werden
                    </p>
                    <p class="py-6 font-extralight leading-relaxed">
                        Jetzt steht Ihrem Traumbad nicht mehr im Wege
                    </p>
                </li>
                <li class="md:px-10 w-full md:w-2/6">
                    <img src="@/assets/images/werkzeug.png" width="75" class="m-auto" loading="lazy">
                    <h6 class="text-2xl py-8 md:px-8">3. Schritt <br> Bad sanieren</h6>
                    <p class="font-extralight leading-relaxed">
                        Sobald Sie sich für unser Planungsangebot entscheiden haben, kommen wir zu Ihnen nach Hause.
                        Bei allen Badsanierungen erbringen wir sämtliche arbeiten selbst, lediglich aus den Bereichen Sanitär- und
                        Elektrotechnik arbeiten wir mit regional ansässigen Handwerksfirmen zusammen. Aufgrund der jahrelangen Zusammenarbeit
                        sind alle Gewerke perfekt aufeinander abgestimmt.
                    </p>
                </li>
            </ul>
        </div>
    </section>

    <section class="p-10 md:p-20 bg-bad-gray-light">
        <div class="container">
            <h1 class="text-3xl font-semibold">Planung mit Köpfchen</h1>
            <div class="flex flex-col lg:flex-row">
                <p class="column-count-2 gap-10 py-5 md:pr-12 leading-relaxed">
                    Damit das Endergebnis begeistert, muss alles mit viel Weitsicht geplant werden. Eine gemeinsame Besichtigung
                    vor Ort ist die Voraussetzung, um einen Eindruck vom Raum zu bekommen und um sich mit den technischen Gegebenheiten
                    vertraut zu machen. Welche Materialien mit welchen Farbstimmungen kommen gefühlvoll zum Einsatz? Wo werden Naturstein,
                    Fliesen oder warme Holzflächen integriert oder verlegt, wo liegt das Augenmerk für die Möglichkeit einer Lichtgestaltung.
                    Wo haben die Sanitärobjekte ihren besten Platz? Oder haben Sie den individuellen Wunsch von Möbeleinbauten? Dieser
                    Raum soll auch Ihre persönliche Handschrift tragen. Auf Basis Ihrer Wünsche und unseren Ideen bekommen Sie eine perfekte
                    3D Animation mit einem genauen Einrichtungsplan.
                </p>
                <img class="m-auto w-full lg:w-1/3" src="@/assets/images/bad.jpg" loading="lazy">
            </div>
            <div class="flex flex-col m-auto lg:flex-row items-bottom">
                <div class="w-full lg:w-1/2 pt-10">
                    <mini-slider/>
                </div>

                <!-- <img class="mt-10 order-last md:order-none m-auto" src="@/assets/images/bad_uebersicht.png" width="600" loading="lazy"> -->
                <div class="w-full lg:w-1/2 md:px-10 pt-10 text-bottom flex flex-col">
                    <img class="mx-auto mr-0" src="@/assets/images/baeder_glaenzen.png" width="400" loading="lazy">
                    <div class="m-auto mb-0">
                        <h3 class="text-3xl font-semibold pb-5">
                        Gespür für das beste Material
                        </h3>
                        <p class="leading-relaxed">
                            Qualität fängt bei uns mit der Auswahl an hochwertigen Materialien an. Ihre Fliesen für besondere Räume.
                            Dazu setzten wir erstklassige Qualitätsprodukte mit hoher Lebensdauer ein und nutzten verschiedenste
                            Verlegetechniken die einen moderne Fliesenverlegung heute bietet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-bad-gray-light">
        <div class="container">

        </div>
    </section>
    <section class="bg-bad-gray-light">
        <image-slider/>
    </section>
   <section class="bg-bad-gray p-10 md:p-20">
        <div class="container">
            <div class="grid grid-cols-1 md:grid-cols-2 text-bad-gray-dark">
                <div>
                    <h4 class="text-4xl"> Was kostet mein neues Bad? </h4>
                    <p class="py-6 leading-relaxed">
                        Die Kosten einer Badrenovierung oder für den Neubau eines Bades richten sich nach der Größe des Raumes
                        und den eigenen Ansprüchen. Wie groß ist das Bad und wie luxuriös dürfen Ausstattung und Gestaltung sein?
                    </p>
                    <p class="leading-relaxed">
                        Sie haben schon ein Angebot für Ihr neues Badezimmer? Gerne erstellen wir Ihnen ein kostenpflichtiges Vergleichsangebot.
                        Nutzen Sie das Einsparpotential, das sich Ihnen dadurch bietet. Meist lassen sich dadurch einige hundert oder sogar
                        tausend Euro einsparen.
                    </p>
                    <button @click="scroll('kontakt')" class="bg-highlight text-white font-extralight p-3 rounded my-8 uppercase">Jetzt kostenloses Angebot anfordern</button>
                </div>
                <div class="m-auto">
                    <img src="@/assets/images/faires_angebot.png" width="450" loading="lazy">
                </div>
            </div>
        </div>
    </section>
    <section class="bg-bad-gray-light p-10 md:p-20">
        <div class="container">
            <h3 class="text-2xl md:text-4xl text-center font-semibold">Das sagen unsere Kunden</h3>
            <ul class="flex flex-col md:flex-row text-center justify-between items-top pt-20">
                <li class="md:px-10 w-full md:w-4/12">
                    <ul>
                        <li class="pb-2"><img class="m-auto" width="25" src="@/assets/images/google.png"></li>
                        <li><img class="m-auto" width="160" src="@/assets/images/stars.png" loading="lazy"></li>
                        <li class="py-5">
                            <p> Positiv: Preis-Leistungs-Verhältnis, Professionalität, Pünktlichkeit,
                                Qualität, Reaktionsschnelligkeit bei Anfragen
                            </p>
                        </li>
                        <li class="py-5">
                            <p> Super Kommunikation mit dem Team von Fleisen & Naturstein Sarecz&nbsp;GmbH, sehr saubere
                                Verarbeitung und Absprachen wurden durchgehend eingehalten - wir würden das Unternehmen immer
                                wieder beauftragen.
                            </p>
                        </li>
                        <li>
                            <span> <i>- Katharina Kuhlee </i> </span>
                        </li>
                    </ul>
                </li>
                <li class="px-10 pt-16 md:py-0 w-full md:w-4/12">
                    <ul>
                        <li class="pb-2"><img class="m-auto" width="25" src="@/assets/images/google.png"></li>
                        <li><img class="m-auto" width="160" src="@/assets/images/stars.png" loading="lazy"></li>
                        <li class="py-5">
                            <p>
                                Es wurde Zeit- nach 20 Jahren haben wir uns entschlossen unser Bad in unserem Privathaus
                                neu zu gestalten. Die Firma Sarecz hat uns von der Idee bis zum fertigen Ausbau perfekt betreut.
                                Alle Gewerke wurden termingerecht koordiniert. Von den Fliesen bis zur Sanitärkeramik eine sehr gute
                                Qualität und perfekter Einbau. Großer Dank an die Firma Sarecz. Das nächste Projekt mit Firma Sarecz ist
                                bereits in Planung
                            </p>
                        </li>
                        <li>
                            <span> <i>- Augustus Apotheke </i> </span>
                        </li>
                    </ul>
                </li>
                <li class="w-full pt-16 md:py-0 md:w-4/12">
                    <ul>
                        <li class="pb-2"><img class="m-auto" width="25" src="@/assets/images/google.png"></li>
                        <li><img class="m-auto" width="160" src="@/assets/images/stars.png" loading="lazy"></li>
                        <li class="py-5">
                            <p> Positiv: Pünktlichkeit, Qualität,
                                <br> Reaktionsschnelligkeit bei Anfragen </p>
                        </li>
                        <li class="py-5">
                            <p>
                                Ich habe meine komplette Wohnung von Fliesen & Naturstein Sarecz fliesen lassen und bin absolut überzeugt.
                                Gute Qualität, sauberes und exaktes Arbeiten. Und bei Reperaturen wurde alles schnell und unkompliziert in
                                Ordnung gebracht. Absolut empfehlenswert
                            </p>
                        </li>
                        <li>
                            <span> <i>- Frauke Wiemer </i> </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </section>
    <section id="kontakt" class="content--big form-section bg-cover bg-no-repeat bg-bottom text-white p-10 md:p-20">
        <div class="container text-center">
            <h1 class="text-4xl">Starten Sie jetzt mit uns Ihre Badplanung!</h1>
            <p>Ob Badsanierung, neue Fliesen im Wohnbereich oder Balkon- und Terrassenarbeiten:</p>
            <p>Mit uns haben Sie immer den passenden Ansprechpartner</p>

            <form autocomplete="on" class="flex flex-col justify-center w-full md:w-2/3 lg:w-2/6 m-auto pt-10 text-bad-gray-dark">
                <input  class="mb-5 p-4 rounded font-light" v-model="email.name" @keyup="updateOutputURL()" type="text" id="name" name="name" placeholder="Name / Vorname" required>
                <input class="mb-5 p-4 rounded font-light" v-model="email.mail" @keyup="updateOutputURL()" type="text" id="email" name="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                <input class="mb-5 p-4 rounded font-light" v-model="email.phone" @keyup="updateOutputURL()" type="text" id="phone" name="phone" placeholder="Telefonnummer">
                <textarea class="mb-5 p-4 rounded font-light" v-model="email.message" @keyup="updateOutputURL()" id="body" name="body" rows="4" cols="50" placeholder="Kurze Projektbeschreibung"></textarea>
                <!-- <input class="bg-highlight text-white font-extralight p-3 rounded mt-6 mb-2 uppercase" type="submit" value="jetzt abschicken"> -->
                <input type="hidden" id="final-link-to-copy" :value="outputUrl" />
            </form>
            <div class="m-auto w-full md:w-2/6">
                <p class="text-sm text-left text-white mb-5">Anfrage- und Auftragsdaten werden gemäß unserer <router-link class="text-highlight" to="/datenschutzerklaerung"><u>Datenschutzerklärung</u></router-link> verarbeitet.</p>
                <a class="block w-full py-5 bg-highlight text-white font-extralight rounded uppercase" :href="outputUrl">Jetzt abschicken</a>
                <p class="text-left text-sm p-0 text-white mt-5">Ihre Anfrage ist: <img class="h-5 inline" src="@/assets/check.svg"> kostenfrei und <img class="h-5 inline" src="@/assets/check.svg"> unverbindlich</p>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import ImageSlider from './ImageSwiper.vue'
import MiniSlider from './MiniSwiper.vue'

export default defineComponent({
  name: 'contentComponent',
  components: {
    ImageSlider,
    MiniSlider
  },
  data () {
    return {
      outputUrl: 'Type something',
      emailId: 'info@badwerk-dresden.de',
      email: {
        name: '',
        mail: '',
        phone: '',
        subject: '',
        message: '',
        body: ''
      }
    }
  },
  methods: {
    updateOutputURL () {
      this.outputUrl = 'mailto:' + this.emailId
      const emailKeys = Object.keys(this.email)
      const remaining = emailKeys.filter(
        (key) => this.email[key].trim().length > 0
      )
      if (remaining.length > 0) {
        this.outputUrl += '?'
      }
      this.email.subject = `Anfrage von ${this.email.name}`
      this.email.body = `
    Kontakt: ${this.email.name}
    Telefonnummer: ${this.email.phone}
    Email: ${this.email.mail},
    Nachricht:
    ${this.email.message}
      `
      this.outputUrl += remaining
        .map((key) => `${key}=${encodeURI(this.email[key].trim())}`)
        .join('&')
    },
    scroll (sectionID) {
      return document.getElementById(sectionID)?.scrollIntoView({ behavior: 'smooth' })
    }
  }
})
</script>
<style lang="scss">
.form-section {
    background: url("~@/assets/images/bg_frau_bad.jpg");
}

.bad-card {
    &--right {
        background: url("~@/assets/images/bg_bad_2.jpg");
    }

    &--left {
        background: url("~@/assets/images/bg_bad_3.jpg");
    }
}

.bg_logo {
    background: url("~@/assets/images/logo_big.png");
}
</style>
