
import { defineComponent } from 'vue'
import ImageSlider from './ImageSwiper.vue'
import MiniSlider from './MiniSwiper.vue'

export default defineComponent({
  name: 'contentComponent',
  components: {
    ImageSlider,
    MiniSlider
  },
  data () {
    return {
      outputUrl: 'Type something',
      emailId: 'info@badwerk-dresden.de',
      email: {
        name: '',
        mail: '',
        phone: '',
        subject: '',
        message: '',
        body: ''
      }
    }
  },
  methods: {
    updateOutputURL () {
      this.outputUrl = 'mailto:' + this.emailId
      const emailKeys = Object.keys(this.email)
      const remaining = emailKeys.filter(
        (key) => this.email[key].trim().length > 0
      )
      if (remaining.length > 0) {
        this.outputUrl += '?'
      }
      this.email.subject = `Anfrage von ${this.email.name}`
      this.email.body = `
    Kontakt: ${this.email.name}
    Telefonnummer: ${this.email.phone}
    Email: ${this.email.mail},
    Nachricht:
    ${this.email.message}
      `
      this.outputUrl += remaining
        .map((key) => `${key}=${encodeURI(this.email[key].trim())}`)
        .join('&')
    },
    scroll (sectionID) {
      return document.getElementById(sectionID)?.scrollIntoView({ behavior: 'smooth' })
    }
  }
})
