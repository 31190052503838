<template>
  <swiper
    :slides-per-view="1"
    :lazy="true"
    :loop="true"
    :space-between="50"
     :navigation="true"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide>
        <img src="@/assets/images/slider_bg/bodengleiche_dusche.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/barockes_badezimmer.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/budget_badezimmer.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/dusche_und_badwanne.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/duschplatz_mit_wandnische.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/exklusive_badsanierung.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/fliesen_mit_natursteinoptik.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/freistehende_badewanne.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/individuelle_badideen.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/kleines_badezimmer.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/landhaus_badrenovierung.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/luxus_baeder.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/moderne_fliesen.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/mosaik_badezimmer.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/naturstein_waschbecken.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/retro_industrielles_bad.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/wandarmatur_badezimmer.jpg">
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/images/slider_bg/waschtisch_schreiner.jpg">
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import { defineComponent } from '@vue/runtime-core'

SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange
    }
  }
})
</script>

<style lang="scss">
:root {
    --swiper-theme-color: #ffffff;
}
</style>
