<template>
    <nav class="bg-bad-gray-light">
        <div class="container flex flex-col items-center md:flex-row lg:justify-between align-middle p-6 mx-auto">
            <!-- Icon Start -->
            <div class="md:w-2/4">
                <router-link to="/"><img src="@/assets/images/logo.svg" class="m-auto md:ml-0" width="200"></router-link>
            </div>
            <!-- Icon End -->
            <div class="flex flex-col md:flex-row content-end pt-3 md:pt-0">
                <img src="@/assets/images/beraten.png" class="order-last md:order-none m-auto pt-3 md:pt-0 md:pr-2" width="150">
                    <a href="#" class="bg-highlight rounded-full w-full md:w-4/5 m-auto text-white p-3 flex justify-center items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/></svg>
                                <span class="ml-2 whitespace-nowrap">035206 394880</span></a
                    >
                </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'LandingPage'
})
</script>

<style></style>
